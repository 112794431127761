import { useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import styles from "./Header.module.scss";

function Header(props: any) {
  const headerRef = useRef<HTMLElement>(null);
  const navigationRef = useRef<HTMLDivElement>(null);

  const toggleNavigation = () => {
    let currentRef = navigationRef.current;

    if (currentRef) {
      if (!currentRef.classList.contains("open")) {
        currentRef.classList.add("open");
      } else {
        currentRef.classList.remove("open");
      }
    }
  }

  return <header className={styles.component} ref={headerRef}>
    <div className="wrapper">
      <div className="logo">
        <Link to={"/"} className="logo-pwe">
          <img src={process.env.PUBLIC_URL + "/logo-pwe-white.svg"} alt="Powerweekend 2025" />
        </Link>
      </div>

      <div className="navigation" ref={navigationRef}>
        <div className="open-navigation" onClick={() => toggleNavigation()}><span></span></div>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/">Home</NavLink>
        {/*<NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/programm">Programm</NavLink>*/}
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/plauschturnier">Mixed Plauschturnier</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/waldgartencup">Waldgarten-Cup</NavLink>
        {/*<NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/einsatzplan">Einsatzplan</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/spielplan">Spielplan Turniere</NavLink>
        <NavLink className={({ isActive }) => (isActive ? "active" : "")} onClick={() => toggleNavigation()} to="/archiv">Archiv</NavLink>*/}
      </div>
    </div>
  </header>
}

export default Header;