import React, { useEffect, useState } from 'react';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './footer/Footer';
import Header from './header/Header';
import NotificationFlyout from './notification/NotificationFlyout';
import Tournament from './forms/Tournament';
import Eis from './forms/Eis';

function App() {
  const [notification, setNotification] = useState<any>();
  const { pathname } = useLocation();

  useEffect(() => {
    document.querySelector(".main__content__wrapper")?.scrollTo(0, 0);
  }, [pathname]);

  const GetHome = () => {
    return <div>
      <h1>Powerweekend - FC Heimberg</h1>
      <p>Das schönste blau-schwarze Wochenende des Jahres findet auch in diesem Jahr im Sommer statt und wir freuen uns, euch alle am 20. und 21. Juni 2025 auf dem Waldgarten in Heimberg begrüssen zu dürfen!</p>
      <p>Auch in diesem Jahr planen wir wieder fix mit unseren Freunden des <b>FC Thun</b> im Rahmen des Powerweekends. Das Challenge League Team - oder zum Zeitpunkt des Powerweekends eventuell die Super League Aufstiegsmannschaft - bestreitet am Samstag ein Vorbereitsungsspiel auf dem Waldgarten. Der Gegner sowie die Anspielzeit werden zu gegebenem Zeitpunkt kommuniziert.</p>
      <p>Während am Samstag also vor allem professioneller Fussball auf dem Programm stehen wird, starten wir bereits am Freitagabend ins Powerweekend! Und auch der Einstieg wird sportlich sein: Unsere Grümpelturniere, aufgeteilt ins <Link to="/plauschturnier">Mixed Plauschturnier</Link> (just for fun) sowie in den <Link to="/waldgartencup">Waldgarten-Cup</Link> (mit Turnierpreisen), eröffnen traditionellerweise das Powerweekend. Melde dich und deine Mannschaft ab sofort für eines der beiden Turniere an.</p>
      <p>Und was wäre ein Powerweekend ohne gute Musik, Party und ausgelassener Stimmung? An beiden Abenden wird die Festwirtschaft geöffnet sein und an der Bar werden leckere Drinks ausgeschenkt.</p>
      <p>Die Vorfreude bei uns ist da - und wir hoffen, dass auch ihr euch auf dieses besondere Wochenende freut.</p>
      <p>Weitere Details sowie das detailierte Programm werden laufend auf dieser Homepage aufgeschaltet.</p>
    </div>;
  }

  const GetProgram = () => {
    return <div>
      <h1>Powerweekend - Programm 2025</h1>
      <p>Folgendes Rahmenprogramm ist für das diesjährige Powerweekend vorgesehen:</p>
      <div className="pwe-teaser">
        <div>
          <img src={process.env.PUBLIC_URL + "/pwe2024_plakat-rgb.jpg?neu"} alt="Powerweekend 2025" />
        </div>
        <div>
          <h4>Freitag, 20. Juni 2025</h4>
          <ul>
            <li>Festwirtschaft ab ca. 17:00 Uhr</li>
            <li>ab 18:00 Uhr: <Link to="/plauschturnier">Mixed Plauschturnier</Link></li>
            <li>ab 18:00 Uhr: <Link to="/waldgartencup">Waldgarten-Cup</Link></li>
            <li>Bar und Unterhaltung mit DJ @Tomic bis in die Morgenstunden</li>
          </ul>

          <h4>Samstag, 21. Juni 2025</h4>
          <ul>
            <li>Festwirtschaft ab ca. 09:30 Uhr</li>
            <li>09:45 Uhr: Start <a href={process.env.PUBLIC_URL + "/sponsorenlauf-2024.pdf"} target="_blank">Sponsorenlauf der Juniorenabteilung</a></li>
            <li>12:30 Uhr: <a href='https://fcthun.ch/' target='_blank'>FC Thun</a> gegen <a href='https://www.yverdonsport.ch/' target='_blank'>Yverdon Sport FC</a></li>
            <li>15:00 Uhr: <a href='https://fcthun.ch/' target='_blank'>FC Thun</a> gegen <a href='https://fcz.ch/' target='_blank'>FC Zürich</a></li>
            <li>Barbetrieb bis 00:30 Uhr</li>
          </ul>
        </div>

      </div>
    </div>;
  }

  const GetPlauschturnier = () => {
    return <div>
      <h1>Mixed Plauschturnier</h1>
      <p>Das Mixed Plauschturnier (ehemals Dorfturnier) richtet sich an alle Dorf- und Quartiervereine, Guggen, Cliquen, Familien, Freundschaften, welche rein zum Vergnügen wiedermal zusammen ein Fussballturnier bestreiten möchten.</p>
      <p>Der Spass und die Teilnahme steht bei diesem Turnier im Vordergrund. Gespielt wird auf kleinen Feldern. Pro Mannschaft sollten mindestens 6 Spieler*innen vorhanden sein, <b>mindestens ein Kind oder eine Spielerin muss stets aktiv auf dem Feld spielen</b>.</p>
      <p>Mit untenstehendem Formular könnt ihr eure Mannschaft direkt online anmelden. Eine automatische Anmeldebestätigung erfolgt anschliessend an die angegebene E-Mail-Adresse.</p>
      <div className="signup">
        <div className="signup__info">
          <h4>Informationen</h4>
          <ul>
            <li>Alle teilnehmenden Equipen erhalten ein Nachtessen</li>
            <li>Das Turnier findet am 20. Juni 2025 statt und beginnt um ca. 18:00 Uhr</li>
            <li>Die Finalspiele werden am selben Abend durchgeführt</li>
            <li>Teilnahmegebühr pro Equipe: Fr. 100.-</li>
            <li>Anmeldeschluss ist am 13. Juni 2025</li>
            <li><a href={process.env.PUBLIC_URL + "/reglement-pwe2025.pdf"} target="_blank">Turnierreglement</a></li>
          </ul>
        </div>
        <div className="signup__form">
          <h4>Anmeldeformular Mixed Plauschturnier</h4>
          <Tournament formType="plauschturnier" setNotification={setNotification}></Tournament>
        </div>
      </div>
    </div>;
  }

  const GetWaldgartencup = () => {
    return <div>
      <h1>Waldgarten-Cup</h1>
      <p>Beim Waldgarten-Cup (ehemals Firmen- und Vereinsturnier) messen sich die ambitionierten Hobbykicker im fussballerischen Wettkampf gegeneinander.</p>
      <p>Bei diesem Grümpelturnier geht es nebst dem Spass auch um Ruhm und Ehre. Gespielt wird auf kleinen Feldern. Pro Mannschaft sollten mindestens 6 Spieler*innen vorhanden sein.</p>
      <p>Mit untenstehendem Formular könnt ihr eure Mannschaft direkt online anmelden. Eine automatische Anmeldebestätigung erfolgt anschliessend an die angegebene E-Mail-Adresse.</p>
      <div className="signup">
        <div className="signup__info">
          <h4>Informationen</h4>
          <ul>
            <li>Attraktive Preise zu gewinnen</li>
            <li>Das Turnier findet am 20. Juni 2025 statt und beginnt um ca. 18:00 Uhr</li>
            <li>Die Finalspiele werden am selben Abend durchgeführt</li>
            <li>Teilnahmegebühr pro Equipe: Fr. 100.-</li>
            <li>Anmeldeschluss ist am 13. Juni 2025</li>
            <li><a href={process.env.PUBLIC_URL + "/reglement-pwe2025.pdf"} target="_blank">Turnierreglement</a></li>
          </ul>
        </div>
        <div className="signup__form">
          <h4>Anmeldeformular Waldgarten-Cup</h4>
          <Tournament formType="waldgartencup" setNotification={setNotification}></Tournament>
        </div>
      </div>
    </div>;
  }

  const GetEisForm = () => {
    return <div>
      <h1>Einsatzplanung</h1>
      <p>Mithilfe dieses Formulars hast du die Möglichkeit, deinen Einsatztag für das diesjährige Powerweekend selbst zu wählen. Einfach das Formular komplett ausfällen und absenden, um deinen Wunsch-Einsatztag zu bestimmen. Die angegebenen Zeiten sind als Richtzeiten zu verstehen, die genaue Einsatzzeit und Aufgabe entnimmst du dann bitte dem Einsatzplan, welcher rund 2-3 Wochen vor dem Powerweekend kommuniziert wird.</p>
      <p>Die definitiven Einsatzzeiten sowie deren Einsatzdauer innerhalb des gewählten Blocks werden mit der Publikation des Einsatzplans folgen. Nicht eingetragene Vereinsmitglieder werden automatisch einem freien Block zugeteilt.</p>
      <p><i>Bitte beachte: In Ausnahmefällen kann es vorkommen, dass wir gezwungen sind deinen Einsatz auf einen anderen Tag als gewünscht zu verschieben. Wir geben aber unser bestes, deinen Wunsch zu respektieren.</i></p>
      <div className="signup">
        <div className="signup__info">
          <h4>Informationen</h4>
          <ul>
            <li>Die angegebenen Einsatzzeiten sind Richtwerte und können sich vom finalen Einsatzplan unterscheiden</li>
            <li>Die Senioren 40+ sind grundsätzlich als Gruppe bereits für einen Einsatz vorgesehen. Solltet ihr individuell euren Einsatz planen wollen, könnt ihr selbstverständlich hier euren Wunsch-Einsatzblock ebenfalls eintragen.</li>
            <li>Falls du während dem Powerweekend abwesend bist, musst du dich auf gewohntem Weg abmelden. Unentschuldigtes Fehlen wird gemäss Bussenkatalog geahndet.</li>
            <li>Das Einsatzplanungsformular ist bis am 9. Juni aufgeschaltet, anschliessend werden die Pläne erstellt.</li>
          </ul>
        </div>
        <div className="signup__form">
          <h4>Formular für Einsatzplanungswünsche</h4>
          <Eis setNotification={setNotification}></Eis>
        </div>
      </div>
    </div>;
  }

  const GetEis = () => {
    return <div>
      <h1>Einsatzplan</h1>
      <div className="info"><b>Bitte beachtet:</b> Der Einsatzplan (bzw. vor allem die Einsatzzeiten am Samstag) mussten aufgrund der neuen Anspielzeiten nochmals angepasst werden. Danke für eure Kenntnisnahme.</div>
      <p>Das Powerweekend wäre ohne die Mithilfe aller Mitglieder des FC Heimberg nicht möglich. An dieser Stelle danken wir dir bereits jetzt für deinen Einsatz vor, während und nach dem Powerweekend!</p>
      <p>In folgenden PDF-Dateien findest du deine Einsatzzeit, dein Aufgabengebiet sowie die verantwortliche Person für den entsprechenden Bereich. Wir bitten dich, dich pünktlich zum Beginn deines Einsatzes bei der verantwortlichen Person zu melden und zählen auf deine Unterstützung.</p>
      <ul>
        <li><a href={process.env.PUBLIC_URL + "/pwe2024_einsatzplan-freitag.pdf"} target="_blank">Einsatzplan Freitag</a></li>
        <li><a href={process.env.PUBLIC_URL + "/pwe2024_einsatzplan-samstag.pdf"} target="_blank">Einsatzplan Samstag</a></li>
        <li><a href={process.env.PUBLIC_URL + "/pwe2024_einsatzplan-sonntag.pdf"} target="_blank">Einsatzplan Sonntag</a><br />&nbsp;</li>
        <li><a href={process.env.PUBLIC_URL + "/pwe2024_einsatzplan.pdf"} target="_blank">Einsatzplan komplett</a></li>
      </ul>
    </div>;
  }

  const GetTorunaments = () => {
    return <div>
      <h1>Spielplan Turniere</h1>
      <p>Wir danken euch für eure Anmeldung fürs Mixed Plauschturnier bzw. den Waldgarten-Cup. Wir freuen uns, werdet ihr mit eurem Team ein Teil des Powerweekends sein!</p>
      <p>Die Garderoben im Klubhaus stehen euch ab ca. 17:00 Uhr zur Verfügung. Die ersten Spiele auf den vier Spielfeldern werden pünktlich um 18:00 Uhr angepfiffen. Alle weiteren Details entnehmt ihr bitte dem nachfolgend verlinkten Spielplan sowie dem Turnierreglement.</p>
      <ul>
        <li>Mixed Plauschturnier:
          <ul>
            <li><a href="https://www.tournify.de/live/plouschturnier/schedule" target="_blank">Spielplan</a></li>
            <li><a href="https://www.tournify.de/live/plouschturnier/standings" target="_blank">Ergebnisse</a></li>
          </ul>
        </li>
        <li>Waldgarten Cup:
          <ul>
            <li><a href="https://www.tournify.de/live/waldgartencup/schedule" target="_blank">Spielplan</a></li>
            <li><a href="https://www.tournify.de/live/waldgartencup/standings" target="_blank">Ergebnisse</a></li>
          </ul>
        </li>
        <li><a href={process.env.PUBLIC_URL + "/reglement-pwe2025.pdf"} target="_blank">Turnierreglement</a></li>
      </ul>
    </div>;
  }

  const Get404 = () => {
    return <div>
      <h1>Hie bini da Heimbärg</h1>
      <p>Aber leider existiert an dieser Stelle kein Inhalt.</p>
    </div>;
  }

  return (
    <div className="main">
      <Header></Header>
      {notification &&
        <NotificationFlyout notification={notification} setNotification={setNotification}></NotificationFlyout>
      }
      <div className="main__content__wrapper">
        <div className="main__content">
          <Routes>
            <Route path="/">
              <Route index element={GetHome()} />
              <Route path="plauschturnier" element={GetPlauschturnier()} />
              <Route path="waldgartencup" element={GetWaldgartencup()} />
              {/*<Route path="programm" element={GetProgram()} />
              <Route path="plauschturnier" element={true ? <Navigate to="/spielplan" /> : GetPlauschturnier()} />
              <Route path="waldgartencup" element={true ? <Navigate to="/spielplan" /> : GetWaldgartencup()} />
              <Route path="einsatzplan" element={GetEis()} />
              <Route path="einsatzplanung" element={GetEisForm()} />
              <Route path="spielplan" element={GetTorunaments()} />*/}
              <Route path="*" element={Get404()}
              />
            </Route>
          </Routes>
        </div>
        {/*<img className="map-marker" src={process.env.PUBLIC_URL + "da-heimberg.png"} alt="...hie bini da Heimbärg!" />*/}
      </div>
      <Footer></Footer>
    </div>
  );
}

export default App;
