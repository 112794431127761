import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import formStyles from "../css/form.module.scss";
import { addToLocalStorage, getFromLocalStorage, removeFromLocalStorage } from "../util/localStorageService";

function Tournament(props: any) {
    let { formType, setNotification }: { formType: string, setNotification: Function } = props;

    let teamInputRef = useRef<HTMLInputElement>(null);

    let [submitted, setSubmitted] = useState<boolean>();
    let [formData, setFormData] = useState<TournamentFormData>({ team: "", name: "", address: "", city: "", mail: "" });

    useEffect(() => {
        let storedFormData = getFromLocalStorage(formType, true);
        if (storedFormData) {
            setFormData(storedFormData);
            setSubmitted(true);
        } else {
            resetForm(null);
        }
    }, [formType]);

    const handleInput = (value: string, property: string) => {
        setFormData((currentValue) => Object.assign({}, currentValue, { [property]: value }));
    }

    const formInvalid = () => {
        for (let prop in formData) {
            if (formData[prop].length < 1) {
                return true;
            }
        }

        if (!formData.mail.match(/^\S+@\S+\.\S+$/)) {
            return true;
        }

        return undefined;
    }

    const resetForm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | null) => {
        if (e) {
            e.preventDefault();
        }

        removeFromLocalStorage(formType);
        setFormData({ team: "", name: "", address: "", city: "", mail: "" });
        setSubmitted(false);

        if (e) {
            setTimeout(() => {
                if (teamInputRef.current) {
                    teamInputRef.current.focus();
                }
            }, 100);
        }
    }

    const submit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!formInvalid()) {
            axios.post("https://powerweekend." + (window.location.hostname === "localhost" ? "local" : "ch") + "/api/tournament", { ...formData, tournament: formType })
                .then((response) => {
                    addToLocalStorage(formType, formData);
                    setSubmitted(true);
                    if (response.status === 204) {
                        setNotification({ title: "Danke für deine Anmeldung", body: <p>Eine Kopie deiner Anmeldung haben wir dir via E-Mail zukommen lassen.</p>, type: "success", autoHide: true });
                    } else {
                        setNotification({ title: "Danke für deine Anmeldung", body: <p>Die Bestätigungs-E-Mail konnte zwar nicht versendet werden, deine Anmeldung haben wir aber dennoch bei uns abgespeichert.</p>, type: "success", autoHide: true });
                    }
                })
                .catch((error) => {
                    setNotification({ title: "Da hat etwas nicht geklappt", body: <p>Beim Versuch, deine Anmeldung zu speichern, ist ein Fehler aufgetreten. Bitte versuche es erneut oder melde dich alternativ via E-Mail für das Turnier an.</p>, type: "error", autoHide: false });
                });
        }
    }

    return <form className={formStyles.form}>
        {submitted && <div className="info">
            Du hast dich mit untenstehenden Angaben für das Turnier angemeldet. Möchtest du eine weitere Mannschaft anmelden, klicke bitte <a onClick={resetForm} href="#reset">hier</a>, um das Formular für eine zusätzliche Mannschaft ausfüllen zu können.
        </div>}
        <label>
            <span>Teamname</span>
            <input ref={teamInputRef} disabled={submitted ? true : undefined} type="text" role="presentation" autoComplete="off" placeholder="Teamname" value={formData.team} onChange={(e) => handleInput(e.currentTarget.value, "team")} />
        </label>
        <p>Angaben zur Kontaktperson. Diese wird frühzeitig alle notwendigen Unterlagen erhalten, so dass sich eure Mannschaft optimal auf das Turnier vorbereiten kann.</p>
        <label>
            <span>Vor- und Nachname</span>
            <input disabled={submitted ? true : undefined} type="text" role="presentation" autoComplete="off" placeholder="Vor- und Nachname" value={formData.name} onChange={(e) => handleInput(e.currentTarget.value, "name")} />
        </label>
        <label>
            <span>Adresse</span>
            <input disabled={submitted ? true : undefined} type="text" role="presentation" autoComplete="off" placeholder="Adresse" value={formData.address} onChange={(e) => handleInput(e.currentTarget.value, "address")} />
        </label>
        <label>
            <span>PLZ/Ort</span>
            <input disabled={submitted ? true : undefined} type="text" role="presentation" autoComplete="off" placeholder="PLZ/Ort" value={formData.city} onChange={(e) => handleInput(e.currentTarget.value, "city")} />
        </label>
        <label>
            <span>E-Mail Adresse</span>
            <input disabled={submitted ? true : undefined} type="text" role="presentation" autoComplete="off" placeholder="E-Mail Adresse" value={formData.mail} onChange={(e) => handleInput(e.currentTarget.value, "mail")} />
        </label>

        <button disabled={formInvalid() ? true : submitted ? true : undefined} onClick={submit} className="submit">Anmelden</button>
    </form>
}

interface TournamentFormData {
    team: string;
    name: string;
    address: string;
    city: string;
    mail: string;

    [key: string]: string;
}

export default Tournament;