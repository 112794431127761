function isLocalStorageAvailable() {
    var test = 'test';
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch(e) {
        return false;
    }
}

export function addToLocalStorage(key: string, value: any) {
    if (isLocalStorageAvailable()) {
        let val = value;
        if (typeof value !== "string") {
            val = JSON.stringify(value);
        }
        localStorage.setItem("2025-" + key, val);
    }
}

export function getFromLocalStorage(key: string, asObject: boolean = true) {
    if (isLocalStorageAvailable()) {
        let value = localStorage.getItem("2025-" + key);
        if (asObject && value !== null) {
            return JSON.parse(value);
        }

        return value;
    }
}

export function removeFromLocalStorage(key: string) {
    if (isLocalStorageAvailable()) {
        localStorage.removeItem("2025-" + key);
    }
}